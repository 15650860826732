import { useEffect } from "react";
import NewDiscount from "../components/new_discount/NewDiscount";
// import StorewideSaleState from "../context/storewide_sale/StorewideSaleState";

const Index = () => {
  const IS_MAINTENANCE_MODE = process.env.IS_MAINTENANCE_MODE ?? false;

  if (IS_MAINTENANCE_MODE === true) {
    return (
      <div style={{ textAlign: "center", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh' }}>
        <h1 style={{ fontSize: '40px', fontWeight: '700'}}>Our app is currently under maintenance</h1>
        <p style={{fontSize: '20px', paddingTop: '20px'}}>We are working to bring it back soon. Thank you for your patience!</p>
      </div>
    );
  }
  return (
    <>

      <NewDiscount />

      <div style={{ display: "none" }}>
        <img src="/img/summary-logo.png" />
        <img src="/img/product_filtering_modal.png" />
        <img src="/img/review_modal_icon.png" />
        <img src="/img/upload_csv_modal_icon.png" />
        <img src="/img/save_bulk_price_modal.png" />
        <img src="/img/save_create_sale_modal.png" />
        <img src="/img/drag_sale_modal.png" />
      </div>
    </>
  );
};

export default Index;
